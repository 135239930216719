import { createApp } from 'vue'

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';

import VueLatex from 'vatex'
import { MdPreview} from 'md-editor-v3';
import 'md-editor-v3/lib/preview.css';

import App from './App.vue'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'



const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}


app.use(ElementPlus);
app.use(VueLatex);
app.use(MdPreview);
app.mount('#app');