<template>
	<el-container>
		<el-header background-color="#f6f7f9">
			<el-menu :default-active="activeIndex" 
			class="el-menu-demo" 
			mode="horizontal" 
			background-color="#f6f7f9"
			@select="handleSelect">
				<el-menu-item index="1">在线文档识别工具V0.0</el-menu-item>
				<el-menu-item index="2">公式识别</el-menu-item>
				<el-menu-item index="3">文字识别</el-menu-item>
				<el-menu-item index="4">公式文字识别</el-menu-item>
				<el-menu-item index="5">图片放大</el-menu-item>
			</el-menu>
		</el-header>
		<el-main>
			<el-row class="inputBorder">
				<el-col :span="24">
					<el-text class="mx-1" size="large">
						<el-icon><Edit /></el-icon>&nbsp;输入区域
					</el-text>
				</el-col>
			</el-row>
			<!-- v-model="text"  双向数据绑定-->
			<!-- :text="text"   单向数据绑定-->
			<!-- 输入框需要跟随输入数据的变化，一般就是双向数据绑定-->
			<el-row>
				<el-input type="textarea" v-loading="inputLoading" v-model="text"  placeholder="请输入内容" :rows="9" @paste="handlePaste"/>
			</el-row>
			<el-row class=outputBorder>
				<el-col :span="24">
					<el-text class="mx-1" size="large">
						<el-icon><Edit/></el-icon>&nbsp;输出区域
					</el-text>
				</el-col>
			</el-row>
	
			<div class="markdown-box">
				<el-row v-show="latexShow" style="min-height: 160px;">
					<el-col :span="24"><vue-latex :expression="text"  display-mode /></el-col>
				</el-row>
				<el-row v-show="mdShow" style="min-height: 160px;" >
					<el-col :span="24"><MdPreview :modelValue="text" style="text-align: left;"/></el-col>
				</el-row>
				<el-row :gutter="0" justify="end">
					<el-col :xs="7" :sm="5" :md="3" :lg="2" :xl="2" >
							<el-button type="info">
								<el-icon><Promotion/></el-icon>&nbsp;输出图片
							</el-button>
					</el-col>
					<el-col :xs="7" :sm="5" :md="3" :lg="2" :xl="2">
							<el-button type="info" @click="outputWord" :loading="loading">
								<el-icon><Document/></el-icon>&nbsp;输出 Word
							</el-button>
					</el-col>
				</el-row>
			</div>
		</el-main>
		<el-footer>
			Copyright © wandering
		</el-footer>
	</el-container>
</template>

<script setup>
	import { ref, watch } from 'vue';
	import axios from 'axios';

	// ref 存储单个简单的数据类型
	// rectivate 存储复杂的数组类型
	var text = ref('y=ax+b');    // 使用ref创建响应式数据
	var activeIndex = ref('2')   // 菜单栏选择索引  默认为公式识别
	
	var latexExpression = ref('');   // latex 渲染内容
	latexExpression.value = text.value;
	var latexShow = ref(true)  // latex 显示框状态
	
	var markdown = ref('');    // 公式文字识别渲染内容
	var word = ref('');    // 文字识别渲染内容
	markdown.value = text.value;
	word.value = text.value;

	var mdShow = ref(false)   // markdown 显示框状态
	
	var loading = ref(false);    // 下载按钮状态
	var inputLoading = ref(false);    // 下载按钮状态
	
	markdown.value = text.value;

	
	watch(activeIndex, (newValue, oldValue) => {
		if (oldValue == "2") {
			latexExpression.value = text.value
		} else if (oldValue == "3") {
			word.value = text.value
		} else if (oldValue == "4") {
			markdown.value = text.value
		}

		if (newValue == "2"){
			latexShow.value = true 
			mdShow.value = false
			text.value = latexExpression.value
		} else if (newValue == "3"){
			latexShow.value = false 
			mdShow.value = true
			text.value = word.value
		} else {
			latexShow.value = false 
			mdShow.value = true
			text.value = markdown.value
		}
	})

	const outputWord = async () => {
		try {
			loading.value = true;	
			if (activeIndex.value == "2"){ // latex 需要加上 $$ 进行 latex 解析
				const response = await axios.post('https://ocr.bitnasdaq.vip/api/convert', { text: "$$" + text.value  + "$$"}, {
				responseType: 'blob',          // Important for handling file download
				timeout: 60 * 2 * 1000
				});
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'document.docx');
				document.body.appendChild(link);
				link.click();
				loading.value = false;
			} else {
				const response = await axios.post('https://ocr.bitnasdaq.vip/api/convert', { text: text.value }, {
				responseType: 'blob',          // Important for handling file download
				timeout: 60 * 2 * 1000
				});
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'document.docx');
				document.body.appendChild(link);
				link.click();
				loading.value = false;
			}
		} catch (error) {
			console.log('转换失败: ' + error.message);
			loading.value = false;
		}
	}
	const handleSelect = (index)=> {
		if (index == "1") {
			activeIndex.value = "2";
		} else {
			activeIndex.value = index;
		}
	} 
	const handlePaste = (event) => {
		// 获取剪切板中的数据
		const clipboardData = event.clipboardData || window.clipboardData;
		const items = clipboardData.items;

		// 遍历剪切板中的数据项
		for (let i = 0; i < items.length; i++) {
			const item = items[i];
			if (item.type.indexOf('image') !== -1) {
				// 阻止默认的粘贴行为
				event.preventDefault();
				// 获取图片文件
				const file = item.getAsFile();
				// 创建一个FormData对象来构建发送的数据
				const formData = new FormData();
				formData.append('file', file);
				// 使用fetch API发送POST请求到服务器
				var urlList = {
					"2": 'https://ocr.bitnasdaq.vip/api/imgtolatex',
					"3": 'https://ocr.bitnasdaq.vip/api/md',
					"4": 'https://ocr.bitnasdaq.vip/api/md'
				}
				console.log(urlList[activeIndex.value])
				inputLoading.value = true;
				fetch(urlList[activeIndex.value], {
						method: 'POST',
						body: formData,
						// 如果服务器需要的话，可以添加额外的headers
					})
				.then(response => response.json()) // 假设服务器返回JSON响应
				.then(data => {
					if (data["message"] === "successfully") {
						// 处理服务器响应的数据
						text.value = data["latex"][0]; // 直接更新响应式数据
					}
					inputLoading.value = false;
				})
				.catch((error) => {
					console.error('Error:', error);
					inputLoading.value = false;
				});
				break;
			}
		}
	};
</script>



<style>
#app {
	text-align: center;
	margin-top: 10px;
}
.inputBorder {
	border: 1px solid var(--el-border-color);
	border-radius: 4;
	margin-bottom: 10px;
	text-align: left;
	line-height: 40px;
}

.outputBorder{
	border: 1px solid var(--el-border-color);
	border-radius: 4;
	margin-top: 10px;
	margin-bottom: 10px;
	text-align: left;
	line-height: 40px;
}
.el-footer {
	background-color: #f6f7f9;
	color: #333;
	text-align: left;
	line-height: 60px;
}
.latex-box {
  border: 1px solid #ccc; /* 边框样式 */
  padding: 10px; /* 内边距 */
  margin-top: 0px; /* 外边距 */
  min-height: 180px;
}
.markdown-box {
  border: 1px solid #ccc; /* 边框样式 */
  padding: 10px; /* 内边距 */
  margin-top: 0px; /* 外边距 */
  min-height: 180px;
}
</style>